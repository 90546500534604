<template>
  <!--人员列表区域-->
  <div class="watchBox">
    <!--高德地图-->
    <div id="map"
         ref="commonMap"></div>
    <div class="tool-box-wrapper">
      <div v-for="(tool, index) in tools"
           class="tool-box"
           :key="index"
           @click.stop="toolHandler(tool)"
           :style="
          (tool === 'hierarchy' && showMore.hierarchy) ||
          (tool === 'command' && showMore.command) ||
          (tool === 'search' && showMore.search) ||
          (tool === 'measure' && showMore.measure) ||
          (tool === 'marker' && showMore.marker)
            ? 'opacity: 1;'
            : ''
        ">
        <img :src="icons[tool]" />
        <div class="more-actions"
             v-if="tool === 'hierarchy'"
             v-show="showMore.hierarchy">
          <div class="more-actions-module mask-slider">
            <Row type="flex"
                 align="middle">
              <Col span="4"><img :src="icons['mask']" /></Col>
              <Col span="20">
              <Slider v-model="maskOpacity"
                      show-tip="never"
                      :min="30"
                      @on-input="changeMask"></Slider>
              </Col>
            </Row>

          </div>
          <div class="more-actions-module tile">
            <label class="act-line">
              <input type="radio"
                     v-model="mapLayer"
                     value="平面" />
              <span>平面地图</span>
            </label>
            <label class="act-line">
              <input type="radio"
                     v-model="mapLayer"
                     value="卫星" />
              <span>卫星地图</span>
            </label>
          </div>
          <div class="more-actions-module">
            <label class="act-line">
              <input type="checkbox"
                     value="地名"
                     v-model="mapArea" />
              <span>地名</span>
            </label>
            <label class="act-line">
              <input type="checkbox"
                     value="路网"
                     v-if="$online"
                     v-model="mapArea" />
              <span>路网</span>
            </label>
            <label class="act-line">
              <input type="checkbox"
                     value="虚拟护栏"
                     v-model="mapArea" />
              <span>虚拟护栏</span>
            </label>
          </div>
        </div>
        <div class="more-actions measureBox"
             v-if="tool === 'measure'"
             v-show="showMore.measure">
          <div @click.stop="stadiometryFn"
               :style="{opacity:(measureType==='line'?'1':'0.6')}">
            <span>测距</span>
          </div>
          <div @click.stop="mSurfaceFn"
               :style="{opacity:(measureType==='area'?'1':'0.6')}">
            <span>测面</span>
          </div>
        </div>

      </div>
    </div>
    <div class="psv"
         v-if="psvmodel">
      <div class="psv-name"><span>{{psvname}}</span>
        <Icon type="md-close"
              style="cursor: pointer;"
              @click="closePsv"
              size=20 />
      </div>
      <div id="viewer"></div>
      <div class="psv-footer">
        <img :src="videoFullscreen"
             @click="toPSV"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import measure from '@/assets/img/Icon/measure.png';
import hierarchy from '@/assets/img/Icon/hierarchy.png';
import zoomIn from '@/assets/img/Icon/jia.png';
import zoomOut from '@/assets/img/Icon/jian.png';
import fix from '@/assets/img/Icon/fix.png';
import mask from '@/assets/img/Icon/mask.png';
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png';
import png3D from '@/assets/img/Marker/3D.png';
import png2D from '@/assets/img/Marker/2D.png';
import parpng from '@/assets/img/Marker/parpng.png';
import {
  GetNotFlyArea,
  PanoramawebList,
  PanoramawebDetail,
} from '@/utils/javaApi.js';
import { Viewer } from 'photo-sphere-viewer';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import Api from '@/utils/api.js';

export default {
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'));
    this.initMap();
  },
  data() {
    return {
      map: '',
      layer: '',
      satelliteLayer: '',
      tools: ['fix', 'hierarchy', 'measure', 'zoomIn', 'zoomOut'],
      showMore: {
        hierarchy: false,
        search: false,
        measure: false,
        marker: false,
        markerModal: false,
        command: false,
      },
      mapLayer: '卫星',
      mapArea: [],
      NFzone: [],
      maskOpacity: 65,
      measureType: 'line', // area
      viewer: null,
      icons: {
        measure,
        hierarchy,
        zoomIn,
        zoomOut,
        fix,
        mask,
        png3D,
        png2D,
        parpng,
      },
      loadingImg: require('@/assets/photosphere-logo.gif'),
      psvmodel: false,
      psvPath: '',
      psvid: '',
      psvname: '',
      videoFullscreen,
    };
  },
  watch: {
    mapLayer(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer);
        // this.map.add(this.roadNetLayer);
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer);
      }
    },
    mapArea(val) {
      //路网,虚拟护栏显示
      // 路网
      if (val.indexOf('路网') !== -1) {
        this.map.add(this.roadNetLayer);
      } else {
        this.map.remove(this.roadNetLayer);
      }
      if (val.indexOf('虚拟护栏') !== -1) {
        if (this.NFzone.length > 0) {
          //   this.map.add(this.NFzone);
          this.NFzone.forEach((element) => {
            element.show();
          });
        } else {
          this.initNFzone();
        }
      } else {
        this.NFzone.forEach((element) => {
          element.hide();
        });
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        let features = ['bg', 'road', 'building', 'point'];
        this.map.setFeatures(features);
      } else {
        let features = ['bg', 'road', 'building'];
        this.map.setFeatures(features);
      }
    },
  },
  methods: {
    initMap() {
      if (this.$online) {
        this.layer = new AMap.TileLayer();
        // 卫星图
        this.satelliteLayer = new AMap.TileLayer.Satellite();
        // 路网图
        this.roadNetLayer = new AMap.TileLayer.RoadNet();
      } else {
        this.layer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
          },
          zIndex: 10,
        });
        this.satelliteLayer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png';
          },
          zIndex: 10,
        });
      }
      this.map = new AMap.Map('map', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        // expandZoomRange:true,
        // zooms:[3,20],
        zoom: 14, //初始化地图层级
        center: this.$Center, //初始化地图中心点
        // crs: "EPSG4326", // 声明坐标系
        // 获取瓦片文件
        layers: [this.layer, this.satelliteLayer],
      });
      //   this.map.add(this.satelliteLayer);
      // this.map.add(xyzTileLayer);
      // 修改主题样式
      //   if (this.$online) {
      //     this.map.setMapStyle('amap://styles/whitesmoke');
      //   }
      var features = ['bg', 'road', 'building'];
      this.map.setFeatures(features);

      let outer = [
        new AMap.LngLat(-360, 90, true),
        new AMap.LngLat(-360, -90, true),
        new AMap.LngLat(360, -90, true),
        new AMap.LngLat(360, 90, true),
      ];

      this.mask = new AMap.Polygon({
        path: outer,
        strokeColor: '#000',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 1 - this.maskOpacity / 100,
        bubble: true,
      });
      this.map.add(this.mask);
      // 注册高德测量
      this.mouseTool = new AMap.MouseTool(this.map);
      this.LDResource();
    },
    toolHandler(type) {
      console.log(type);
      // 指点飞行和群体飞控禁止其他地图操作
      switch (type) {
        case 'measure':
          this.showMore.measure = !this.showMore.measure;
          if (this.showMore.measure) {
            this.draw();
          } else {
            this.mouseTool.close(true); // 设为true，关闭测量工具后清除地图上的测量
          }
          break;
        case 'hierarchy':
          this.showMore.hierarchy = !this.showMore.hierarchy;
          break;
        case 'zoomIn':
          this.map.zoomIn();
          break;
        case 'zoomOut':
          this.map.zoomOut();
          break;
        case 'fix':
          this.backCenter();
          break;
        default:
          break;
      }
    },
    /**
     * 虚拟护栏 NFzone
     */
    // 初始化虚拟护栏
    initNFzone() {
      let that = this;
      this.$post(GetNotFlyArea(), {
        team_id: that.user_info.team_id,
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = [];
            element.local_data.forEach((ele) => {
              path.push(
                // new AMap.LngLat(
                //   window.gcj02towgs84(ele.lng,ele.lat)[0],
                //   window.gcj02towgs84(ele.lng, ele.lat)[1]
                // )
                new AMap.LngLat(ele.lng, ele.lat)
              );
            });

            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            });
            that.NFzone.push(polygon);
            that.map.add(polygon);
          });
        }
      });
    },

    backCenter() {
      this.map.setCenter(this.$Center);
      // 关闭地图tool
      this.showMore.hierarchy = false;
      this.showMore.command = false;
      this.showMore.search = false;
      this.showMore.measure = false;
      this.showMore.marker = false;
      this.showMore.markerModal = false;
    },
    //获取一张图坐标点
    LDResource() {
      console.log('LDResource');
      let that = this;
      this.$post(Api.loadAllResource(), {
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
        type: 0,
        bf_name: '',
        page: 1,
        limit: 100,
      })
        .then((res) => {
          that.initMark(res.data.data[0].data_list);
        })
        .catch((err) => {
          this.$Message.error(err);
        });
      this.$post(PanoramawebList(), {
        page: 1,
        limit: 100,
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
      })
        .then((res) => {
          that.initMark(res.data.data);
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
    //渲染坐标点
    initMark(data) {
      let that = this;
      let arr = [];
      data.forEach((ele) => {
        if (ele.lng) {
          if (ele.mode_type == 1) {
            //三维
            let fireman = new AMap.Marker({
              position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              label: {
                offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                content: "<div class='info'>" + ele.mapname + '</div>', //设置文本标注内容
                direction: 'top', //设置文本标注方位
              },
              icon: new AMap.Icon({
                size: new AMap.Size(60, 60), // 图标尺寸
                image: this.icons.png3D, // Icon的图像
                // image: ele.img,
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              extData: { dateID: ele.id },
            });
            fireman.on('click', that.showInfoIt1);
            arr.push(fireman);
            that.map.add(fireman);
          } else if (ele.mode_type == 2) {
            //正射
            let fireman = new AMap.Marker({
              position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              label: {
                offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                content: "<div class='info'>" + ele.mapname + '</div>', //设置文本标注内容
                direction: 'top', //设置文本标注方位
              },
              icon: new AMap.Icon({
                size: new AMap.Size(60, 60), // 图标尺寸
                image: this.icons.png2D, // Icon的图像
                // image: ele.img,
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              extData: { dateID: ele.id },
            });
            fireman.on('click', that.showInfoIt2);
            arr.push(fireman);
            that.map.add(fireman);
          } else if (ele.mode_type == 3) {
            //全景
            let fireman = new AMap.Marker({
              position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              label: {
                offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                content: "<div class='info'>" + ele.name + '</div>', //设置文本标注内容
                direction: 'top', //设置文本标注方位
              },
              icon: new AMap.Icon({
                size: new AMap.Size(60, 60), // 图标尺寸
                image: this.icons.parpng, // Icon的图像
                // image: ele.img,
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              extData: { dateID: ele.id },
            });
            fireman.on('click', that.showInfoQJ);
            arr.push(fireman);
            that.map.add(fireman);
          }
        }
      });
      this.map.setFitView(arr);
    },
    showInfoIt1(e) {
      this.$router.push({
        name: 'ModelList',
        query: { id: e.target.getExtData().dateID },
      });
    },
    showInfoIt2(e) {
      this.$router.push({
        path: '/orthographicShou',
        query: { id: e.target.getExtData().dateID },
      });
    },
    showInfoQJ(e) {
      if (this.psvmodel) {
        this.closePsv();
        this.$nextTick(() => {
          this.psvmodel = true;
          this.psvid = e.target.getExtData().dateID;
          this.initpsv();
        });
      } else {
        this.psvmodel = true;
        this.psvid = e.target.getExtData().dateID;
        this.initpsv();
      }
    },
    toPSV() {
      this.$router.push({
        name: 'EdtiPanorama',
        query: { id: this.psvid, watch: 2 },
      });
    },

    // 初始化全景
    initpsv() {
      this.$post(PanoramawebDetail(), { panorama_id: this.psvid }).then(
        (res) => {
          if (res.code === 1) {
            //   this.psvPath = result.panoramaImgPath;
            this.psvname = res.data[0].name;
            this.viewer = new Viewer({
              container: 'viewer',
              panorama: res.data[0].panoramaImgPath,
              loadingImg: this.loadingImg,
              //   touchmoveTwoFingers: true,
              // mousewheelCtrlKey: true,
              // caption: this.caption,
              // defaultLong: '100deg',
              // plugins: [MarkersPlugin],
              defaultLat: -Math.PI / 2,
              defaultLong: Math.PI,
              defaultZoomLvl: 0,
              // fisheye: 4,
            });
          }
        }
      );
    },
    closePsv() {
      this.psvname = '';
      this.psvid = '';
      this.viewer = '';
      this.psvmodel = false;
    },

    // 测距
    stadiometryFn() {
      this.measureType = 'line';
      this.draw();
    },
    // 测面积
    mSurfaceFn() {
      this.measureType = 'area';
      this.draw();
    },
    draw() {
      var _this = this;
      switch (_this.measureType) {
        case 'line': {
          _this.ruler = _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF4949',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          });
          break;
        }
        case 'area': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          });
          break;
        }
      }
    },

    // 蒙版透明度
    changeMask(e) {
      this.mask.setOptions({ fillOpacity: 1 - this.maskOpacity / 100 });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.watchBox {
  width: 100%;
  height: 100%;
  position: relative;
  #map {
    width: 100%;
    height: 100%;
  }
  .tool-box-wrapper {
    position: absolute;
    bottom: 0;
    right: 0.2rem;
    padding-bottom: 0.3rem;
    transition: all 0.3s ease-in-out;

    .tool-box {
      margin-top: 0.2rem;
      width: 0.3rem;
      height: 0.3rem;
      background: #000;
      opacity: 0.7;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        opacity: 1;
      }

      img {
        width: 18px;
      }

      .more-actions {
        width: 180px;
        height: 240px;
        position: absolute;
        color: #fff;
        bottom: 0;
        right: 35px;
        display: flex;
        flex-direction: column;

        .more-actions-module {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 13px 28px 24px;
          .act-line {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 14px;
            &:last-child {
              margin-bottom: 0;
            }
            input {
              margin-right: 13px;
              width: 16px;
              height: 16px;
            }
            span {
              white-space: nowrap;
            }
          }
        }
        .mask-slider {
          padding: 2px 14px;
          margin-bottom: 10px;
        }
        .tile {
          padding: 24px 28px 13px;
          border-bottom: 1px solid #707070;
        }
      }

      .measureBox {
        height: 30px;
        padding: 0;
        background-color: transparent;
        flex-direction: row;

        > div {
          width: 85px;
          white-space: nowrap;
          background-color: #000;
          opacity: 0.6;
          border-radius: 2px;
          margin-right: 10px;
          padding: 0.03rem 0;
          text-align: center;

          span {
            font-size: 16px;
            color: #fff;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        :nth-child(1) {
          opacity: 1;
        }
      }
    }
  }
  .psv {
    width: 470px;
    height: 355px;
    position: absolute;
    top: 30px;
    left: 30px;
    background-color: #000;
    color: #fff;
    #viewer {
      width: 100%;
      height: 280px;
    }
    .psv-name {
      height: 35px;
      line-height: 35px;
      padding-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }
    .psv-footer {
      height: 40px;
      padding-right: 14px;
      img {
        cursor: pointer;
        float: right;
        margin-top: 10px;
      }
    }
  }
}
</style>
